<template>
  <div class="scene"></div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.scene {
    
}
</style>