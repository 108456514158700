<template>
  <div>
    <section class="orenji-layer layer1">
      <header-view />
      <div class="orenji-bg bg1"></div>
      <div class="orenji-home-content">
        <h2 class="fadeInDownBig animated delay1">専用ホスト</h2>
        <p class="txt fadeInDown animated delay2">
          リソース専用、物理的に隔離のクラウドコンピューティングサービスにより、リソース専用、セキュリティ、コンプライアンスのニーズを満たします
        </p>
      </div>
    </section>
    <section class="orenji-layer layer2">
      <div class="orenji-bg bg2"></div>
      <div class="orenji-home-content">
        <h2 class="fadeInDownBig">製品導入</h2>
        <p class="txt">
          CVM Dedicated
          Host（CDH）はユーザー専用の物理的サーバーリソースを提供することにより、リソース専用、リソースの物理的な隔離、セキュリティ、コンプライアンスのニーズを満たします。CDHはTencent
          Cloud仮想化システムを搭載しています。購入していただくと、それで複数のカスタマイズした仕様のCVMインスタンスを柔軟に新規作成・管理し、自主的に物理的リソースの利用を計画することができます。
        </p>
      </div>
    </section>
    <section class="orenji-layer layer3">
      <div class="orenji-bg bg3"></div>
      <div class="orenji-home-content">
        <h2 style="font-size: 40px">製品説明</h2>
        <detail-view />
      </div>
    </section>
    <section class="orenji-layer layer4">
      <div class="orenji-bg bg4"></div>
      <div class="orenji-home-content">
        <h2 style="font-size: 40px">製品の特徴</h2>
        <ability-view />
      </div>
    </section>
    <!-- <section class="orenji-layer layer5">
      <div class="orenji-bg bg5"></div>
      <div class="orenji-home-content">
        <h2 style="font-size: 40px">ユースケース</h2>
        <scene-view />
      </div>
    </section> -->
    <section class="orenji-layer layer6">
      <footer-view />
    </section>
  </div>
</template>

<script>
import headerView from "@/components/layout/header";
import footerView from "@/components/layout/footer";
import detailView from "@/components/orenji/ecs/detail";
import abilityView from "@/components/orenji/ecs/ability";
import sceneView from "@/components/orenji/ecs/scene";

export default {
  name: "orenji",
  computed: {},
  components: {
    headerView,
    footerView,
    detailView,
    abilityView,
    sceneView,
  },
};
</script>

<style lang="less" scoped>
.orenji-layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &.layer1 {
    top: 0;
  }

  &.layer2 {
    top: 100%;
  }

  &.layer3 {
    top: 200%;
    color: #333333;
    background: #f4f4f4;
  }

  &.layer4 {
    top: 300%;
    color: #333333;
    background: #ffffff;
  }

  // &.layer5 {
  //   top: 400%;
  // }
  &.layer6 {
    top: 400%;
    height: 219px;
  }
}

.orenji-bg {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;

  &.bg1 {
    background-image: url("../../../public/images/ecs_01.png");
  }

  &.bg2 {
    background-image: url("../../../public/images/ecs_02.png");
  }

  &.bg5 {
    background: #032456;
  }
}

.text {
  margin: 0 40px;
}

.orenji-home-content {
  width: 1320px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  font-weight: normal;
  text-align: center;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  h2 {
    font-weight: 300;
    letter-spacing: 0.3rem;
    font-size: 50px;
    text-align: center;
    margin-bottom: 3rem;
  }
}

.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}

.delay1 {
  .durationDelay(1s, 0.5s);
}

.delay2 {
  .durationDelay(1s, 1s);
}

.delay3 {
  .durationDelay(1s, 1.5s);
}

.delay4 {
  .durationDelay(1s, 2s);
}
</style>