<template>
  <div>
    <div class="ability">
      <ul>
        <li
          v-for="(itm, idx) in list"
          @click="activeIndex = idx"
          :key="idx"
          :class="{ active: activeIndex === idx }"
        >
          <div>
            <h4>{{ itm.title }}</h4>
          </div>
          <section>
            <p>{{ itm.text }}</p>
          </section>
        </li>
      </ul>
      <section class="text-describe">
        <h3>{{ list[activeIndex].title }}</h3>
        <section v-for="item in list[activeIndex].list">
          <p
            v-for="(itm, idx) in item"
            :style="{
              fontWeight: idx === 0 ? 'bold' : 'normal',
              marginBottom: idx === 0 ? '3px' : '0',
            }"
          >
            {{ itm }}
          </p>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      activeIndex: 0,
      list: [
        {
          title: "ホストタイプ",
          list: [
            [
              "豊かなモデルコンフィグレーション",
              "現在、CDHは標準型、メモリ型、コンピューティング型、高IO型の4種類のモデルをサポートしています。",
              "標準型：バランスの取れたコンピューティング、メモリとネットワークリソースにより、大部分のユースケースにおけるアプリケーションリソースニーズを満たすことができます。",
              "メモリ型：大容量メモリの特徴があり、高性能データベース、分散式メモリキャッシュなど、大容量メモリで操作、検索やコンピューティングを行う必要のあるアプリケーションにしています。",
              "コンピューティング型：最大のシングルコアコンピューティング性能を備えています。バッチ処理、高性能コンピューティングや大型ゲームサーバなど、集中的にコンピューティングを取り扱うアプリケーションに適しています。",
              "ハイIO型：ランダム性の高いIOPS、高スループット、低アクセスレイテンシーなどの特徴があり、ディスクへの書き込み及びレイテンシーに対する要求の高い高性能データベースなどのI/O集中型アプリケーションに適しています。",
            ],
            [
              "柔軟的な新規作成及びコンフィグレーション調整",
              "一つ、複数CDHなどの方式を指定しCVMを新規作成することをサポートし、CVMは仕様の制限がない、ホストリソースによりカスタマイズし、リソースの計画と分配を自主的に効率よく行うことが可能です。豊かなコンピューティング、ネットワークやディスクの選択と調整を提供しています。現在CDHにおけるCVMのCPU、メモリのアップグレード、ダウングレード、及びディスクコンフィグレーションのアップグレードをサポートしています。",
            ],
            [
              "マルチリージョン、マルチアベイラビリティーゾーン",
              "Tencent Cloud Virtual Machineのホスティングデータセンターは世界中の多くのリージョンに分散しています。ターゲット顧客に最寄りのリージョンを選びCDHをデプロイすることができることにより、アクセスレイテンシーを大幅に下げ、業務体験を向上します。",
              "各リージョンには複数の相互隔離のアベイラビリティーゾーンがあります。業務に対し高い災害復帰能力が必要である場合は、CDHインスタンスを同じリージョンの異なるアベイラビリティーゾーンにデプロイし、アプリケーションが一つのアベイラビリティーゾーンの障害に影響されないことを保証することができます。",
            ],
          ],
        },
        {
          title: "イメージ",
          list: [
            [
              "豊かなイメージソース",
              "Blue- Pointer は下記の3種類のイメージを提供します。",
              "パブリックイメージ：Tencent Cloud側より提供され、基本OSとTencent提供の初期化コンポーネントから構成し、すべてのユーザーは利用することができます。",
              "カスタマイズイメージ：ユーザーがイメージ作成機能で作成したイメージです。ユーザーの作成したイメージは同アカウントのみ利用できます。",
              "共有イメージ：ほかのユーザーの共有により得られたイメージです。インスタンスの新規作成のみ利用できます。",
            ],
            [
              "サーバーの作動環境をバックアップ",
              "カスタマイズイメージはサーバーのシステムディスクをバックアップすることに使われることが可能です。データ紛失が起こる時に、迅速にリカバーすることができます。",
            ],
            [
              "イメージのクロスリージョンレプリケーション",
              "Blue- Pointer の異なるリージョンの優勢を利用するために、カスタマイズイメージをほかのTencent Cloudリージョンにレプリケートする機能を提供しています。もっとカスタマイズイメージ操作に関する情報を理解します。",
            ],
            [
              "ソフトウエア環境のバッチデプロイ",
              "環境をデプロイしたCVMに対しイメージを作成し、CVMをバッチで新規作成することにより、ソフトウエア環境の迅速的なバッチデプロイを実現します。",
            ],
          ],
        },
        {
          title: "クラウドハードドライブ",
          list: [
            [
              "高い信頼性",
              "CBSはデータブロックレベルの永続ストレージデバイスであり、高い可用性と信頼性の特徴を備え、データ信頼性が99.999999%に達し、業界をリードするレベルになっています。CBSは三つのレプリカストレージ仕組みを採用し、いずれかのレプリカが故障した時に速やかにデータのマイグレーションと復帰を行い、随時にユーザデータの三つのレプリカの利用可能を保証し、安全で安心できるデータストレージサービスを提供します。",
            ],
            [
              "高性能",
              "3種類のCBS（一般的CBS、高性能CBSとSSD CBS）をサポートし、異なるケースにおけるニーズを満たします。その内、SSD CBSはNUMe標準の高性能SSDを採用しており、シングルディスクが24000ランダムIOPS、260MB/sスループットを提供し、高性能と高い信頼性を同時に実現しています。",
            ],
            [
              "スナップショットのバックアップ",
              "CBSでスナップショットを新規作成し、データのバックアップレプリカを確保することにより、随時スナップショットから新しいCBSを新規作成することが可能です。また、それをもう一つのインスタンスに接続することにより、業務の柔軟性を高めることができます。",
              "スナップショットの詳細を理解するには >",
            ],
            [
              "拡張性",
              "業務を中断することなく、CBSのストレージ容量を自由に構成し、オンデマンドのスケールアウトを行うことが可能です。シングルディスクの容量は10～16000GBであり、シングルCVMは累計で160TBのストレージをマウントすることができます。シングルCBSクラスターのストレージ容量は上限がなく、 TB / PB レベルのビッグデータ処理ケースを高効率的に対応できます。",
            ],
          ],
        },
        {
          title: "ネットワーク",
          list: [
            [
              "高品質",
              "Blue- Pointer は高品質のBGPネットワークを提供し、超高速のパブリックネットワーク体験をもたらします。BGPネットワークは国内におけるすべての主流キャリアのアクセスをサポートし、全国20回線以上をカバーしている超高速パブリックネットワーク体験により、パブリックネットワークのエントリーは障害が起こる時にクロスリージョンの秒レベルのスケジューリングを実現しています。",
            ],
            [
              "セキュリティ",
              "VPCによりクラウドにおいて独立したネットワークスペースをあらかじめ設定することが可能となります。カスタマイズした仮想ネットワークでクラウドリソースを起動することができます。企業のデータセンターとVPCの間でVPN又は専用回線接続を新規作成し、Tencent Cloudを企業のデータセンターの拡張とすることも可能です。",
              "ACLによりVPCにおける各サブネットのイン・アウトのトラフィックをコントロールします。",
              "セキュリティグループポリシーを設定することにより、各インスタンスのイン・アウトのトラフィックをコントロールすることができます。",
            ],
            [
              "Elastic IP",
              "ElasticパブリックネットワークIPアドレスは、ダイナミッククラウドコンピューティングに向け設計されたスタティックIPアドレスです。ElasticパブリックネットワークIPアドレスをアカウントにおけるCVMとバインディングすることができます。このCVMは障害が起こった場合、このIPを速やかにほかのCVMインスタンスにバインディングできることにより、業務の可用性を保証します。",
              "ElasticパブリックネットワークIPに関する詳細を理解するには >",
            ],
            [
              "監視",
              "CDH、CVMの多次元監視をサポートしています。CPU利用率、メモリ利用率、ディスク利用率などのような監視指標が提供されています。CVMは多種類指標のアラームトリガー閾値の設定をサポートしています。アラーム受信者と配信チャネルをカスタマイズします。",
            ],
          ],
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.ability {
  margin: 40px auto 0;
  display: flex;
  justify-content: flex-start;
  min-height: 670px;
  > ul {
    width: calc(25% - 51px);
    display: flex;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 50px;
    li {
      position: relative;
      width: 100%;
      text-align: left;
      padding: 5px;
      border-radius: 5px;
      margin: 15px 0;
      box-sizing: border-box;
      background: #fff;
      height: auto;
      cursor: pointer;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      &:hover {
        background: rgb(229, 229, 229);
      }
      &.active {
        &::after {
          position: absolute;
          content: "";
          display: block;
          top: 0;
          right: -1px;
          width: 1px;
          height: 100%;
          border-right: 1px solid rgba(29, 139, 218, 1);
        }
      }
    }
  }
  > .text-describe {
    width: 75%;
    text-align: left;
    > h3 {
      margin-bottom: 15px;
      font-size: 20px;
    }
    > section {
      color: #666666;
      margin-bottom: 20px;
      font-size: 15px;
      > p {
        line-height: 1.8;
      }
    }
  }
}
</style>
