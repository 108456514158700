<template>
  <div>
    <div class="describe-content">
      <ul>
        <li v-for="itm in list">
          <div>
            <img ondragstart="return false;" :src="itm.icon" />
            <h4>{{ itm.title }}</h4>
          </div>
          <section>
            <p>{{ itm.text }}</p>
          </section>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      list: [
        {
          icon: "/images/icon/icon_ecs_001.svg",
          title: "リソース専用",
          text: "CDHは物理マシンレベルの専用リソースを提供します。自主的にホスト内のリソース利用を計画することにより、ほかのテナントとのリソース競争を避けることができます。Tencent Cloudのコンソール又はAPIインターフェースにより、数分間で迅速にCDHを購入できます。",
        },
        {
          icon: "/images/icon/icon_ecs_002.svg",
          title: "セキュリティ・コンプライアンス",
          text: "CPU、メモリ、ディスク、ネットワークリソースはシングルテナント専用になります。物理マシンレベルのリソース隔離により、機密業務データのプロテクション、ディスク消磁機能を提供し、金融業界の厳しい監督・管理要求を満たします。",
        },
        {
          icon: "/images/icon/icon_ecs_003.svg",
          title: "自主的で柔軟性がある",
          text: "専用CVM、即ちCDHで新規作成したCVMは、イメージ、セキュリティグループ、コンフィグレーション調整、SSHキーなどをサポートし、各機能・特徴の利用方式も一般的なCVMと同じです。",
        },
        {
          icon: "/images/icon/icon_ecs_004.svg",
          title: "CVMの特徴を備えている",
          text: "ネットワークアクセス制御リストとセキュリティグループを通じて、クラウドリソースのセキュリティを効果的に確保できます。",
        },
        {
          icon: "/images/icon/icon_ecs_005.svg",
          title: "アセットライト",
          text: "オンデマンド購入、分レベルのデリバリー、Tencent Cloudの統一標準のメンテナンス管理サービスにより、リソースの安定した運行を保証します。顧客は基盤のメンテナンスを注目する必要がないことにより、人力・メンテナンスコストを節約し、コアビジネスに集中することが可能となります。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.describe-content {
  margin: 40px auto 0;
  text-align: left;
  > ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      padding: 30px 40px;
      box-sizing: border-box;
      width: calc(100% / 3 - 2px);
      max-width: calc(100% / 3);
      background: #fff;
      height: auto;
      border-left: 1px solid rgba(56, 96, 244, 0.1);
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        border-bottom: 1px solid rgba(56, 96, 244, 0.1);
      }
      &:nth-of-type(3n + 1) {
        border-left: 0;
      }
      > div {
        > img {
          width: 45px;
          height: 45px;
          vertical-align: middle;
          background-position: center;
        }

        > h4 {
          margin-left: 10px;
          display: inline-block;
          font-size: 20px;
          vertical-align: middle;
        }
      }
      > section {
        margin-top: 15px;
        > p {
          color: #7a8ca6;
          font-size: 15px;
        }
      }
      &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
